<template>
  <div>
    <div class="w-title">
      <h1 class="title">Mon panier</h1>
      <p>Tous les articles affichés ici, sont les articles que vous avez ajouté à votre panier.</p>
      <p>Ces articles seront ceux ajoutés au devis qui vous sera généré, et que vous devrez envoyer par mail.</p>
    </div>

    <hr class="solid">

    <div v-if="itemsCount > 0">
      <div class="cart-container" v-if="itemsCount > 0">
        <CartItem v-for="(item, index) in getItems" :key="index" v-bind:item="item"></CartItem>
      </div>

      <hr class="solid">

      <div class="titles">
        <p class="title">Total: {{ getTotalPrice.toFixed(2) }} €</p>
        <p class="title">Caution: {{ getCautionPrice.toFixed(2) }} €</p>
      </div>


      <div class="cart-buttons">
        <b-button type="is-danger is-light" icon-left="delete" @click="clearCart">Vider mon panier</b-button>
        <b-button type="is-link is-light" icon-left="skip-next" @click="nextStep">Passer à l'étape suivante</b-button>
      </div>

    </div>

    <div v-else>
      <p class="title">Votre panier est vide.</p>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import CartItem from "@/components/CartItem";

export default {
  name: "Cart",
  components: {CartItem},
  computed: {
    ...mapGetters("cart", ["itemsCount", "getItems", "getTotalPrice", "getCautionPrice", "needsMemberCard", "hasMemberCard"]),
    ...mapGetters("data", ["getMemberCard"])
  },

  methods: {
    clearCart() {
      this.$buefy.dialog.confirm({
        title: 'Vider mon panier',
        message: 'Etes-vous sûr de vouloir vider votre panier?',
        cancelText: 'Annuler',
        confirmText: 'Vider mon panier',
        hasIcon: true,
        type: "is-danger",
        onConfirm: () => this.$store.dispatch("cart/clearCart", this.item).then(() => this.$buefy.toast.open('Panier bien vidé'))
      });
    },

    nextStep() {
      if (!this.hasMemberCard && this.needsMemberCard) {
        this.$buefy.dialog.confirm({
          title: 'Carte membre nécessaire',
          message: 'Une carte membre est nécessaire (<b>17.00 €</b>)<br>Principe de l\'association',
          cancelText: 'J\'en possède déjà une',
          confirmText: 'Ajouter au panier',
          hasIcon: true,
          type: "is-info",
          onConfirm: () => { // add member card to cart
            let card = JSON.parse(JSON.stringify(this.getMemberCard))
            card._amount_ = 1
            card._id_ = 'member_card_' + Math.random().toString(36).substr(2, 9);
            card.options = []

            this.$store.dispatch("cart/addToCart", card).then(() => this.$buefy.toast.open('Carte membre bien ajoutée.'))
          },

          onCancel: () => { // set hasMemberCard to true
            this.$store.commit('quote/setStatus', 0)
            this.$router.push('quote')
          }
        });
        return;
      }

      // Pas de mélange
      // If item added is a goodies, check if no items other than goodies are in cart
      let items = this.getItems
      let goodiesCount = 0, otherCount=  0;

      for (const valueKey in items) {
        let value = items[valueKey]

        if (value.category_id === process.env.VUE_APP_GOODIES_CATEGORY_ID)
        {
          goodiesCount += 1
          continue
        }

        if(value.name === process.env.VUE_APP_GOODIES_MEMBER_CARD_NAME)
          continue

        otherCount += 1
      }

      if(goodiesCount !== 0 && otherCount !== 0)
      {
        this.$buefy.toast.open({
          message: "Impossible d'ajouter l'article au panier :<br>Il n'est pas possible de mélanger goodies et locations.",
          position: 'is-top',
          type: 'is-danger',
          hasIcon: true,
          duration: 1000 * 7
        })
        return
      }

      this.$store.commit('quote/setStatus', 0)
      this.$router.push('quote')
    }
  }
}
</script>

<style scoped>
.cart-container {
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 30px;
}

.cart-buttons button {
  margin: 5px;
}

.titles {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
}
</style>