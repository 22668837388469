<template>
  <div class="cart-item w-shadow">

    <div class="desktop">
      <div class="infos">
        <p class="price">{{ item.name }}</p>
        <p class="description">{{ item.shortDescription }}</p>
        <p class="option">
          <b-icon icon="subdirectory-arrow-right"></b-icon>
          Prix
        </p>
        <p v-for="(option, index) in item.options.filter(t => t._selected_)" :key="index" class="option">
          <b-icon icon="subdirectory-arrow-right"></b-icon>
          {{ option.name }} ({{ option.description }})
        </p>
        <p class="caution"/>
        <p class="option" style="text-align: center">Sous-total</p>
      </div>

      <div class="actions">
        <p class="price">Quantité: {{ item.amountText.replace('{amount}', item._amount_) }}</p>
        <br class="option"/>
        <p class="option">{{ generatePrice(false, item.price) }}</p>
        <p v-for="(option, index) in item.options.filter(t => t._selected_)" :key="index" class="option">
          {{ generatePrice(option.fixedPrice, option.price) }}
        </p>
        <p class="caution"/>
        <p class="option">{{ calculateTotal().toFixed(2) }}€</p>

      </div>

      <div class="cautions">
        <p class="price">Caution: {{ item.cautionText.replace('{price}', item.caution) }}</p>
        <br class="option"/>
        <p class="option">{{ generatePrice(item.fixedCaution, item.caution) }}</p>
        <p class="option" v-for="(option, index) in item.options.filter(t => t._selected_)" :key="index">
          <br/>
        </p>
        <p class="caution"/>
        <p class="option">{{ (item.fixedCaution ? item.caution : item._amount_ * item.caution).toFixed(2) }}€</p>
      </div>

      <div class="buttons">
        <b-button type="is-danger" icon-right="delete" @click="deleteItem"></b-button>
      </div>

    </div>

    <div class="mobile">
      <div class="infos">
      <p class="title">{{item.name}}</p>
      <p class="description">{{item.shortDescription}}</p>

      <p class="option">
        <b-icon icon="subdirectory-arrow-right"></b-icon>
        Prix : {{ generatePrice(false, item.price) }}
      </p>

      <p v-for="(option, index) in item.options.filter(t => t._selected_)" :key="index">
        <b-icon icon="subdirectory-arrow-right"></b-icon>
        {{ option.name }} : {{ generatePrice(option.fixedPrice, option.price) }}
      </p>

      <p style="border-top: 1px solid">Caution: {{ generatePrice(item.fixedCaution, item.caution) }}</p>
      <p>Sous-total: {{ calculateTotal().toFixed(2) }}€</p>
      </div>
      <div class="buttons">
        <b-button type="is-danger" icon-right="delete" @click="deleteItem"></b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartItem",
  props: {
    item: {}
  },
  methods: {
    generatePrice(fixed, price) {

      if (fixed) return price.toFixed(2) + '€';



      let result = this.item._amount_ + ' x ';

      result += price.toFixed(2);
      result += '€ = ';
      result += (this.item._amount_ * price).toFixed(2) + '€';

      return result;
    },
    calculateTotal() {
      let total = 0;
      let item = this.item
      let amount = item._amount_

      total += amount * item.price

      for (let i = 0; i < item.options.length; i++) {
        let option = item.options[i]

        total += option.fixedPrice ? option.price : amount * option.price
      }

      return total
    },
    deleteItem() {
      this.$buefy.dialog.confirm({
        message: 'Etes-vous sûr de vouloir supprimer cet article?',
        onConfirm: () => {
          this.$store.dispatch("cart/removeFromCart", this.item)
              .then(() => {
                this.$buefy.toast.open('Article bien supprimé')
              });
        }
      });
    }
  }
}
</script>

<style scoped>
.cart-item {
  text-align: left;
  margin: 5px 5px 20px;
  border-radius: 6px;
  padding: 5px;
  background-color: whitesmoke;
}

.cart-item .desktop {
  display: flex;
}

.cart-item .mobile {
  display: none;
}

.cart-item .desktop .infos {
  width: 100%;
}

.cart-item .desktop .actions {
  width: 80%;
}
.cart-item .desktop .cautions {
  width: 80%;
}

.cart-item .desktop .price {
  font-size: 36px;
}

.cart-item .desktop .description {
  font-size: 16px;
}

.cart-item .desktop .option {
  font-size: 20px;
}

.cart-item .desktop .caution {
  font-size: 22px;
  border-bottom: 1px solid gray;
}

@media screen and (max-width: 1423px) {

  .cart-item .desktop {
    display: none;
  }

  .cart-item .mobile {
    display: flex;
  }

  .cart-item .mobile .infos {
    width: 80%;
  }

  .cart-item .mobile .buttons {
    text-align: center;
    width: 20%;
    display: flex;
    justify-content: center;
  }

}
</style>